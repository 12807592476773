import React from 'react';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <footer className='footer mt-auto py-3'>
            <br />
            <div className='container pb-1 pb-lg-5 text-lg-center'>
                <a href="https://app.mufleet.com" target={"_blank"} rel="noreferrer">
                    <button className="btn btn-success">
                        Start now
                    </button>
                </a>
                <br/><br/>
                <p className='small'><a className='no-decoration' href='https://api.mufleet.com/swagger-ui/index.html'
                                        target='_blank' rel="noreferrer">API</a>
                    &nbsp;-&nbsp;<a className='no-decoration'
                                    href='/privacy'
                                    target="_blank" rel="noreferrer">Privacy policy</a>
                </p>
                <p className='small'>help@mufleet.com - © mufleet</p>
                <p className='small'>2023 - {year}</p>
            </div>
        </footer>
    );
}

export default Footer;
