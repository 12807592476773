import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PagePrice() {
    document.title = 'Mufleet - Price';

    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <p>Discover a new standard in fleet management with our cutting-edge tracking solution, now in its alpha stage. </p>
                            <br/>
                            <p>This is your exclusive opportunity to:</p>
                            <ul>
                                <li>
                                    <b>Access Premium Features for Free</b>: Take advantage of advanced fleet tracking and real-time visibility without any cost during the alpha phase.
                                </li>
                                <li>
                                    <b>Shape the Platform</b>: Your feedback can directly influence the development of features tailored to your needs.
                                </li>
                                <li>
                                    <b>Be Ahead of the Curve</b>: Gain early access to innovations that will redefine how fleets are managed globally.
                                </li>
                            </ul>
                            <br/>
                            <p>Sign up today and join a growing community of forward-thinking fleet operators. </p>
                            <p>Spaces are limited, don’t miss your chance to explore, optimize, and lead the way for free!</p>
                    </div>
                    <div className="col-2"></div>
                </div>

                </div>
                <Footer/>
        </Suspense>
    );
}

export default PagePrice;
