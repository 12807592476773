import React, { Suspense } from 'react';
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


// @ts-ignore
function PageFeatures() {
    document.title = 'Mufleet - Features';

    const markdown = `
# Features

### 1) Real-time driver tracking acknowledgment

- **What it does**: confirms that every position update is successfully transmitted from the driver's app, with the sending time displayed in real-time on the screen.
- **Customer benefit**: provides peace of mind by verifying that the tracking data is reaching the system without delays. This transparency helps businesses ensure their operations are always on track.

### 2) Driver tracking issue alerts

- **What it does**: displays a **red circle with a cross** when Mufleet encounters connectivity issues sending position updates.
- **Customer benefit**: instantly alerts fleet drivers of tracking disruptions, enabling rapid resolution to avoid downtime. This ensures you stay informed, even when technical issues arise.

### 3) Permanent device tracking ID

- **What it does**: ensures your device's unique tracking ID remains constant, even after app restarts or reinstallation.
- **Customer benefit**: maintains continuity in tracking, reducing errors and preventing unnecessary reconfiguration. This reliability is especially critical for businesses with high turnover or multiple devices.

### 4) Effortless tracking setup

- **What it does**: simplifies onboarding with a plug-and-play approach, just copy the **device tracking ID** from the app and paste it into the web platform.
- **Customer benefit**: saves time and eliminates technical barriers, making it accessible for users with minimal technical expertise. Perfect for growing fleets that prioritize ease of use.

### 5) Late/Absent telemetry alerts

- **What it does**: highlights vehicles with delayed or absent position updates in **red** on the map, while actively tracking vehicles remain **gray**.
- **Customer benefit**: immediately identifies potential operational issues, enabling swift action to minimize disruptions. This feature enhances fleet accountability and reduces downtime.

### 6) Driver tracking issue alerts

- **What it does**: allows users to organize and filter their fleet based on criteria most relevant to their operations, such as vehicle type, location, or operational status.
- **Customer benefit**: enhances efficiency by letting managers quickly locate specific assets, focus on priority vehicles, and generate actionable insights tailored to their business needs.

### 7) Dynamic fleet map with live updates

- **What it does**: automatically updates the map as new position updates are received, offering an up-to-the-second overview of your fleet’s movements.
- **Customer benefit**: empowers decision-making with live, actionable data. Whether planning routes or responding to issues, you’re always equipped with the latest information.

### 8) Automatic telemetry data cleanup

- **What it does**: automatically deletes location updates older than one month, provided there is more than one update for the device.
- **Customer benefit**: reduces data clutter and ensures your system remains fast and efficient, all while retaining critical recent data. This feature keeps the platform manageable and user-friendly.
`
    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <Markdown remarkPlugins={[remarkGfm]}>
                    {markdown}
                </Markdown>
                <Footer/>
            </div>
        </Suspense>
);
}

export default PageFeatures;
