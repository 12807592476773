import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PageDemo() {
    document.title = 'Mufleet - Demo';

    return (
        <Suspense fallback={<p>loading ...</p>}>
            <div className="container">
                <Header />
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <h3>Driver</h3>
                        <iframe width="100%" height="500"
                                src="https://www.youtube.com/embed/ddOI9Z8dtZs?si=q_DobhO_8p8gDngA"
                                title="mufleet - driver demo" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        <hr />
                        <br/>
                        <h3>Admin</h3>
                        <iframe width="100%" height="500"
                                src="https://www.youtube.com/embed/F8M0l6TqvqQ?si=Hj01o0RbOKGLOslL"
                                title="mufleet - admin demo" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            <Footer />
        </Suspense>
    );
}

export default PageDemo;
