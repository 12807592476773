import React, { Suspense } from 'react';
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


// @ts-ignore
function PageNoData() {
    document.title = 'Mufleet - No data';

    const markdown = `
# Troubleshoot device tracking issues

### 1) App usage issues

- **Click on the green keep awake button**: the app will stay in the foreground and facilitate coordinate sending
- **Wait longer**: it might take longer for your position to be sent, it might take up to **30 minutes** depending of your internet connection
- **Move with the phone**: stimulates the position sending feature of **Mufleet** 
- **Interprete the signal status**: Only the red signal with a white cross and the crossed wifi signal should be considered as connection issue
    - As long as the signal is not red, wait ...
- **Restart your phone**: If your phone is bloated and you have a lot of applications running, restarting the phone might help
- **Reinstall the app**: As last resort, reinstall the app. Do not worry, you will keep the same device ID

### 2) Check device connectivity

- **Physical Inspection**: Ensure the device is properly connected to the internet and powered on.
- **Signal Indicators**: Check if there are any information on the device that provide status on connectivity.

### 3) Verify device configuration

- **Settings**: Access the device settings and confirm that it is configured to allow to location sharing for **Mufleet**.
- **Platform Dashboard**: Login to the **Mufleet** [dashboard](https://app.mufleet.com/fleet) and check the device's status and recent activity logs.

### 4) Inspect power supply

- **Battery Level**: Ensure that the device has sufficient power or is properly charging, if applicable. We recommend at least 15% of battery.
- **Connections**: Check all power connections for faults or loose connections.

### 5) Evaluate network coverage

- **Cellular/Wi-Fi Connection**: Confirm that the device is within range of a network tower or Wi-Fi access point.
- **Interference**: Consider environmental factors or obstructions that could be affecting network coverage.
    - **Physical Obstructions**: Buildings, trees, hills, and other structures can obstruct the cellular signal, reducing connectivity.
    - **Weather Conditions**: Extreme weather like thunderstorms, heavy rain, or snow can negatively impact cellular signals.
    - **Electronic Interference**: Devices like microwaves, cordless phones, and Wi-Fi routers operating on similar frequencies can cause interference.
    - **Network Congestion**: In densely populated areas, too many users accessing the network simultaneously can lead to congestion and reduced internet speeds.
    - **Distance from Cell Towers**: Being too far from a cell tower can weaken signal strength and affect connectivity.
    - **Signal Reflection**: Signals reflecting off surfaces such as glass, metal, or water can cause signal disruption or weakening.
    - **Geographical Topography**: Natural formations like mountains and valleys can disrupt the direct line of sight between the cell phone and the tower.

### 6) Update your phone

- **Check for Updates**: Verify if there are new operating system update available for the device.

### 7) Contact support

- If none of the above steps resolve the issue, please contact our support team for further assistance:
  - **Email**: help@mufleet.com
  
Provide the following information to expedite your support request:
- Device ID
- Email
- Trouble description and steps taken

We hope this guide helps you quickly troubleshoot and resolve issues with devices not sending 

`
    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <Markdown remarkPlugins={[remarkGfm]}>
                    {markdown}
                </Markdown>
                <Footer/>
            </div>
        </Suspense>
);
}

export default PageNoData;
